import React from "react";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import downtown from "../images/backrounds/city-background.jpg";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Call Centers"
        description="Call Centers need to Make the Most of Zendesk! We can train your agents, implement applications, migrate data, and develop any custom solution you need."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${downtown})`,
          }}
        >
          <div
            className="hero-title bg-primary-transparent"
            style={{ backgroundColor: "rgba(76,134,160,0.75)" }}
          >
            <h1 className="text-white">Call Center Partnership</h1>
            <p className="text-white">
              729 Solutions can help you and your customers make the most of
              Zendesk!
            </p>
            <CTA
              href="/contact-us/"
              text="Contact us today"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section className="mt-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
              We're a team of certified zendesk administrators, so you don’t have to be.
              </h2>
              <p>
                We can help your clients with any Zendesk need and train your
                agents to use the tool. Our team is skilled in all areas of
                Zendesk implementation, migration, development, and support.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row my-5">
            <div className="col-12 col-lg-6">
              <h3>We can assist you</h3>
              <ul className="text-primary mt-5">
                <li className="mb-3">
                  <span className="text-dark">Train Admins and Agents</span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Migrate your clients from any other tool to Zendesk
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Implement Zendesk to best practices
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Integrate any software with Zendesk
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Set up Talk, Chat, and Guide
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0">
              <h3>We Guarantee To:</h3>
              <ul className="text-primary mt-5">
                <li className="mb-3">
                  <span className="text-dark">
                    Set up a scoping call within three days
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Provide an estimate within a week
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">Start work within two weeks</span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Offer competitive pricing and do exceptional work
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="container">
          <div className="row text-center">
            <div className="col-12 bg-gray-200 my-5 py-5">
              <p>
                We pay a <b>15% referral fee</b> and will give you or your
                customer a free scoping consultation with project estimates.
                Refer us or subcontract as you please. Trust in 729’s 15 years
                of success and experience as a well-vetted software development
                and DevOps shop.
              </p>
              <CTA
                href="/contact-us/"
                text="See More Of What We Do"
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="container pt-5 bg-primary-alternative">
          <div className="row text-center">
            <div className="col-12 text-white">
              <h2 className="text-white mb-2">Contact Us Today</h2>
              <p>
                Please click the button below to fill out the form. We will
                contact you as soon as possible to schedule a call.
              </p>
              <p>
                You can also send an email to{" "}
                <a href="mailto:graham@729.io?subject=Managed Service Provider">
                  graham@729.io
                </a>{" "}
                to schedule a time to talk about how best to work together.
              </p>
            </div>
            <div className="col-12 my-5">
              <CTA
                href="/contact-us/"
                text="Make A Time To Talk"
                className="btn btn-secondary-light"
              />
            </div>
          </div>
        </div>
      </section>
      <BlogFeed />
    </Layout>
  );
};
export default Index;
